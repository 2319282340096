<template>
  <div class="award_page">
    <section class="section1">
      <div class="title">
        羽素喷雾荣获【2020年新浪美妆口碑榜】<br />
        年度口碑舒缓修护喷雾
      </div>
      <div class="info">
        <img src="@/assets/images/banzhuan/award/award1.jpg" alt="" />
        <div class="desc">
          <div class="fz23">
            2020年12月11日，羽素专为敏感肌定制研发的喷雾<br />
            获得了【2020年新浪美妆口碑榜】年度口碑舒缓修护喷雾
          </div>
          <div class="fz18 mt50">
            羽素，是一个致力于“量肤解决年轻肌肤问题”的品牌，提供1V1深度专业<br />
            服务，为用户提供定制化方案，做到精准解决各类痘痘肌、敏感肌问题。
          </div>
        </div>
      </div>
    </section>
    <section class="section2">
      <div class="title">颜值抗打，永乐宫限量定制</div>
      <div class="desc fz18">
        <div class="mt40">
          2020年，也是羽素“品牌元年”，因此在这一年里，品牌做<br />
          了很多尝试，而和永乐宫跨界联名就是一次全新的挑战，<br />
          而此举不仅让品牌与市场互动感更强了，而且还在用户<br />
          面前展示了一个全新得羽素形象。
        </div>
        <div class="mt40">
          而本次获奖的羽素×永乐宫联名款舒缓喷雾，正是品牌通<br />
          过市场口碑、全面大数据等多维度精选而出的。
        </div>
      </div>
    </section>
    <section class="section3">
      <div class="title">“ICU急救喷雾” 只为敏感肌</div>
      <div class="desc fz18">
        羽素舒缓修护喷雾，被称为“ICU急救喷雾”，是羽素品牌专门为敏感肌研制的。其中，特别添加了3种能真正做到强效舒敏的专利成分——5%维敏休*、
        SymCalmin德敏舒*和甘油磷酸肌醇胆碱盐。通过科学设计的仿生抗炎肽，抑制UVB刺激引发的皮肤炎症，发挥抗组胺、抗氧化、抗刺激、抗炎功效，
        最终解决敏感肌因饮食、季节、紫外线等产生的红、肿、痒、痛问题。
        <div class="mt30">
          更值得一提的是，羽素舒缓修护喷雾通过舒缓效果临床评价、抗炎效果与地塞米松及对比及抗刺激等三重实验后得出，加入5%的维敏休*，是为
        敏感肌舒缓、抗炎、抗刺激效果最佳的科学添加量。
        
        </div>
        <div class="mt40">
          <div class="img">
            <img src="@/assets/images/banzhuan/award/award3.jpg" alt="" class="mt50"/>
          </div>
          <div class="tc mt30">
            （数据来源：羽素联合实验室）
          </div>
          
        </div>
        <div class="mt60">
          为了更科学地证实羽素舒缓修护喷雾的真实有效性，羽素还通过组胺人体实验，得出1小时内，皮肤红痒现象完全消退的结论，让敏感肌见证了
        羽素舒缓喷雾修护的能力，也进一步充分证实了这款产品的完美效果。
        </div>
        <div class="img">
          <img src="@/assets/images/banzhuan/award/award4.jpg" alt="" class="mt50"/>
        </div>
        <div class="tc mt30">
          （数据来源：羽素联合实验室）
        </div>
      </div>
    </section>
    <section class="section4">
      <div class="title">凭借实力，收获市场好口碑</div>
      <div class="desc">
        正因如此，羽素舒缓修护喷雾一经上市，吸引了无数KOL种草，并获得了全网关注。
        “2020年新浪美妆口碑榜”，以全网大数据为基础，综合全民投票及专家评审，
        客观评选并表彰2020年在中国市场上最具市场口碑的品牌及产品，羽素舒缓修
        护喷雾凭借卓越的舒缓、抗炎、抗刺激等良好市场口碑，斩获了“年度口碑舒缓修
        护喷雾”殊荣！ 
        <div class="fz24">
          最后，羽素将继续秉承初心，用最专业的态度，
        研发出更多优秀的产品，只为“量肤解决年轻肌肤问题”
        </div>
        
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Expert',
  components: {},
}
</script>
<style scoped lang='scss'>
.award_page {
  width: 1450px;
  margin: 0 auto;
  line-height: 1.5;
  .title {
    font-size: 30px;
    text-align: center;
  }
  .section1 {
    background: #fffbf4;
    .title {
      padding: 90px 0;
    }
    .info {
      display: flex;
      line-height: 1.8;
      img {
        width: 650px;
        height: 340px;
      }
    }
    .desc {
      flex: 1;
      padding-left: 80px;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: column;
    }
  }
  .section2 {
    background: url('~@/assets/images/banzhuan/award/award2.jpg');
    height: 580px;
    background-size: 100%;
    padding-left: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      text-align: left;
    }
  }
  .section3{
    line-height: 1.8;
    .title{
      margin: 100px auto 40px auto;
    }
  }
  .section4{
    background: #fffbf4;
    text-align: left;
    padding-left: 120px;
    height: 600px;
    margin-top: 80px;
    background:  url('~@/assets/images/banzhuan/award/award5.jpg') no-repeat 940px 0 #fffbf4;
    margin-bottom: 40px;
    .title{
      text-align: left;
      padding: 70px 0 40px;
    }
    .desc{
      width: 700px;
      font-size: 18px;
      line-height: 1.8;
    }
    .fz24{
      margin-top: 200px;
      font-size: 24px;
    }
  }
  .img{
    text-align: center;
  }
  .fz23 {
    font-size: 23px;
  }
  .fz18 {
    font-size: 18px;
  }
  .mt30 {
    margin-top: 30px;
  }
  .mt40 {
    margin-top: 40px;
  }
  .mt50 {
    margin-top: 50px;
  }
  .mt60 {
    margin-top: 60px;
  }
  .tc{
    text-align: center;
  }
}
</style>
