// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/banzhuan/award/award2.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/banzhuan/award/award5.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".award_page[data-v-7b7f73cc]{width:7.55208rem;margin:0 auto;line-height:1.5}.award_page .title[data-v-7b7f73cc]{font-size:.15625rem;text-align:center}.award_page .section1[data-v-7b7f73cc]{background:#fffbf4}.award_page .section1 .title[data-v-7b7f73cc]{padding:.46875rem 0}.award_page .section1 .info[data-v-7b7f73cc]{display:flex;line-height:1.8}.award_page .section1 .info img[data-v-7b7f73cc]{width:3.38542rem;height:1.77083rem}.award_page .section1 .desc[data-v-7b7f73cc]{flex:1;padding-left:.41667rem;display:flex;align-content:center;justify-content:center;flex-direction:column}.award_page .section2[data-v-7b7f73cc]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");height:3.02083rem;background-size:100%;padding-left:.52083rem;display:flex;flex-direction:column;justify-content:center}.award_page .section2 .title[data-v-7b7f73cc]{text-align:left}.award_page .section3[data-v-7b7f73cc]{line-height:1.8}.award_page .section3 .title[data-v-7b7f73cc]{margin:.52083rem auto .20833rem auto}.award_page .section4[data-v-7b7f73cc]{background:#fffbf4;text-align:left;padding-left:.625rem;height:3.125rem;margin-top:.41667rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 4.89583rem 0 #fffbf4;margin-bottom:.20833rem}.award_page .section4 .title[data-v-7b7f73cc]{text-align:left;padding:.36458rem 0 .20833rem}.award_page .section4 .desc[data-v-7b7f73cc]{width:3.64583rem;font-size:.09375rem;line-height:1.8}.award_page .section4 .fz24[data-v-7b7f73cc]{margin-top:1.04167rem;font-size:.125rem}.award_page .img[data-v-7b7f73cc]{text-align:center}.award_page .fz23[data-v-7b7f73cc]{font-size:.11979rem}.award_page .fz18[data-v-7b7f73cc]{font-size:.09375rem}.award_page .mt30[data-v-7b7f73cc]{margin-top:.15625rem}.award_page .mt40[data-v-7b7f73cc]{margin-top:.20833rem}.award_page .mt50[data-v-7b7f73cc]{margin-top:.26042rem}.award_page .mt60[data-v-7b7f73cc]{margin-top:.3125rem}.award_page .tc[data-v-7b7f73cc]{text-align:center}", ""]);
// Exports
module.exports = exports;
